import { Route, Routes } from "react-router";

import { useAdm4Rollback } from "../auth/useAdm4Rollback";

import BeaconsList from "./pages/BeaconsList";

import AuthorizedElement from "@/modules/auth/AuthorizedElement";
import GoToAdm4 from "@/router/GoToAdm4";

const BeaconsRoutes = () => {
  useAdm4Rollback("beacons");
  return (
    <Routes>
      <Route
        index
        element={
          <AuthorizedElement
            requiredRoles="beacons::list"
            element={<BeaconsList />}
          />
        }
      />
      <Route path="*" element={<GoToAdm4 />} />
    </Routes>
  );
};

export default BeaconsRoutes;
