import { FormError, ResponseFormError } from "../../types/sharedTypes";

import { queryClient } from "@/appQueryClient";
import { config } from "@/config";
import { useAuthStore } from "@/modules/auth/AuthContext";

export const mountUrl = (path: string): string => {
  return `${config.api.baseUrl}${path}`;
};

export type FetcherError = {
  status: number;
  message: string;
  errors?: FormError[];
};

function addHeaders(options: RequestInit): RequestInit {
  const requestHeaders: HeadersInit = new Headers(options.headers);
  return {
    ...options,
    headers: requestHeaders,
    credentials: "include",
  };
}

const createConcurrentRefreshTokenHandler = () => {
  let refreshRequest: Promise<Response | void> | undefined = undefined;
  let isValidating = false;
  return (originalFetch: [string, RequestInit, Record<string, unknown>]) => {
    return new Promise((resolve, reject) => {
      const refreshToken = async () => {
        isValidating = true;
        refreshRequest = fetch(
          mountUrl("/auth/token"),
          addHeaders({
            method: "POST",
          })
        ).then((resp) => {
          if (resp.status === 200) {
            if (
              !originalFetch[0].includes("/me") &&
              !originalFetch[0].includes("/auth/logout")
            ) {
              queryClient?.refetchQueries({
                queryKey: ["me"],
              });
            }
            return resp;
          }

          useAuthStore.setState({ authState: "unauthenticated" });
          throw {
            status: resp.status,
            message: "Credentials expired",
          };
        });

        return refreshRequest;
      };

      if (!isValidating) {
        return refreshToken()
          .then(() => {
            isValidating = false;
            return resolve(fetcher(...originalFetch));
          })
          .catch((err) => {
            isValidating = false;
            return reject(err);
          });
      }

      return refreshRequest
        ?.then(() => resolve(fetcher(...originalFetch)))
        .catch(reject);
    });
  };
};

const handleConcurrentRefreshToken = createConcurrentRefreshTokenHandler();

export type FetcherDefaultError = {
  status: number;
  message: string;
};

export type FetcherValidationError = {
  status: number;
  message: string;
  errors: FormError[];
  meta?: Record<string, unknown>;
};

const handleResponse = async <T>(
  response: Response,
  originalFetch: [string, RequestInit, Record<string, unknown>]
) =>
  new Promise<T | null | FetcherError>((resolve, reject) => {
    if (response.ok) {
      return resolve(response.status === 204 ? null : response.json());
    }

    if (response.status === 422) {
      const rejectFormErrors = async (response: Response) => {
        const detail: ResponseFormError[] = (await response.json()).detail;
        const errors: FormError[] = Object.values(
          detail.reduce((acc, { loc: [, ...field], msg, type }) => {
            const fieldName = field.map((f) => f.toString());
            if (fieldName.join(".") === "_general") {
              acc[`${fieldName}|${msg}`] = {
                field: fieldName,
                message: msg,
                type,
              };
            } else {
              const key = `${fieldName}|${type}`;
              acc[key] = acc[key]
                ? { ...acc[key], message: `${acc[key].message}\n${msg}` }
                : { field: fieldName, message: msg, type };
            }
            return acc;
          }, {} as Record<string, FormError>)
        );
        const meta = originalFetch[2];

        return reject({
          status: 422,
          message: "Validation failed",
          errors,
          meta,
        } as FetcherValidationError);
      };

      return rejectFormErrors(response);
    }

    const getDefaultError = async (response: Response) => {
      const detail: string | unknown = (await response.json())?.detail;
      return reject({
        status: response.status,
        message: typeof detail === "string" ? detail : "Unknown error",
      } as FetcherDefaultError);
    };

    if (response.status === 401) {
      if (
        originalFetch[0].includes("/auth/login") ||
        originalFetch[0].includes("/auth/mfa")
      ) {
        return getDefaultError(response);
      }

      return handleConcurrentRefreshToken(originalFetch)
        .then((data) => resolve(data as T))
        .catch(reject);
    }

    return getDefaultError(response);
  });

function trimSlashes(url: string) {
  return url.replace(/^\/+|\/+(?=\?)|\/+$/g, "");
}

async function fetcher<T>(
  url: string,
  options: RequestInit,
  meta: Record<string, unknown>
): Promise<T> {
  const optionsWithHeaders = addHeaders(options);
  url = trimSlashes(url);

  return fetch(url, optionsWithHeaders)
    .catch(() =>
      Promise.reject({
        status: 500,
        message: "Internal server error",
      })
    )
    .then(async (data) =>
      handleResponse(data, [url, optionsWithHeaders, meta])
    ) as Promise<T>;
}

export default fetcher;
