import PersonIcon from "@mui/icons-material/Person";
import {
  Button,
  Chip,
  Stack,
  TableCell,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router";

import Beacon from "../Beacon";
import { BeaconsItem } from "../Beacon.types";

import { useAuth } from "@/modules/auth/AuthContext";
import CustomersSelectField, {
  CustomerOptions,
} from "@/modules/common/components/CustomerAutocomplete";
import RoleBasedEntityFormatter from "@/modules/common/components/EntityFormatter";
import { TableParams } from "@/modules/common/components/table/Table";
import { TableHeaderCellProps } from "@/modules/common/components/table/TableHeaderCell";
import TablePage from "@/modules/common/components/table/TablePage";
import { useTableDefaultRows } from "@/modules/common/components/table/useTableDefaultRows";
import useQueryParams from "@/modules/common/hooks/useQueryParams";

type DefaultParams = TableParams & {
  customer?: string;
  active: boolean | null;
};

const defaultParams: DefaultParams = {
  search: "",
  page: 1,
  perPage: useTableDefaultRows.getState().defaultRows,
  customer: "",
  active: null,
};

const headers: TableHeaderCellProps[] = [
  { label: "Name" },
  { label: "Customer" },
  { label: "Missions" },
  { label: "Type" },
  { label: "Status" },
  { label: "Actions", textAlign: "center" },
];

const BeaconRow = ({
  id,
  name,
  customer,
  missions_count,
  type,
  active,
  showDetail,
}: BeaconsItem & { showDetail: boolean }) => (
  <TableRow key={id}>
    <TableCell>{name}</TableCell>
    <TableCell>
      <RoleBasedEntityFormatter
        module="customers"
        id={customer.id}
        label={customer.name}
      />
    </TableCell>
    <TableCell>{missions_count}</TableCell>
    <TableCell>
      <Chip size="small" label={type} sx={{ width: "70px" }} />
    </TableCell>
    <TableCell>
      {active ? (
        <Chip label="ACTIVE" color="success" variant="outlined" />
      ) : (
        <Chip label="INACTIVE" color="error" variant="outlined" />
      )}
    </TableCell>
    <TableCell align="center">
      {showDetail && (
        <Button variant="contained" component={Link} to={`/beacons/${id}`}>
          Open
        </Button>
      )}
    </TableCell>
  </TableRow>
);

const STATUS_LIST = ["active", "inactive"];
type FilterForm = {
  customer: CustomerOptions | null;
  status?: string;
};
type BeaconsFilterFormProps = {
  initialValues?: FilterForm;
  onChange: SubmitHandler<FilterForm>;
};
const BeaconsFilterForm = ({
  initialValues,
  onChange,
}: BeaconsFilterFormProps) => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      customer: null,
      status: "active",
    },
    values: {
      customer: initialValues?.customer ?? null,
      status: initialValues?.status ?? "",
    },
  });

  const handleSend = handleSubmit(onChange);

  return (
    <Stack direction="row" justifyContent="end" gap={2} mb={4}>
      <Controller
        name="customer"
        control={control}
        render={({ field }) => {
          return (
            <CustomersSelectField
              {...field}
              onChange={(e, value) => {
                field.onChange(value);
                handleSend();
              }}
            />
          );
        }}
      />

      <Controller
        name="status"
        control={control}
        render={({ field }) => {
          return (
            <ToggleButtonGroup
              size="small"
              color="primary"
              exclusive
              {...field}
              onChange={(e, value) => {
                field.onChange(value);
                handleSend();
              }}
            >
              {STATUS_LIST.map((t) => {
                return (
                  <ToggleButton key={t} value={t} size="small">
                    {t}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          );
        }}
      />
    </Stack>
  );
};

const BeaconsList = () => {
  const { checkAuthorization } = useAuth();
  const [showCreate, showDetail] = checkAuthorization([
    "beacons::create",
    "beacons::detail",
  ]);
  const [params, setParams] = useQueryParams(defaultParams);
  const page = parseInt(params.page);
  const perPage = parseInt(params.perPage);
  const { search, customer, active } = params;

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["beacons", { search, customer, active, page, perPage }],

    queryFn: () =>
      Beacon.getList({
        q: search,
        page,
        size: perPage,
        customer,
        active: active === "true" ? true : active === "false" ? false : null,
      }),
  });

  const handleFilterChange = (data: FilterForm) => {
    setParams({
      customer: data.customer?.value ?? "",
      active:
        data.status === "active"
          ? true
          : data.status === "inactive"
          ? false
          : null,
    });
  };

  return (
    <TablePage
      pageTitleIcon={PersonIcon}
      pageTitle="Beacons"
      headers={headers}
      renderRow={(item) => (
        <BeaconRow {...item} key={item.id} showDetail={showDetail} />
      )}
      toolbarAction={
        showCreate && (
          <Button
            variant="contained"
            component={Link}
            to="/beacons/new"
            sx={{ whiteSpace: "nowrap", py: 1 }}
          >
            New Beacon
          </Button>
        )
      }
      aditionalToolbar={<BeaconsFilterForm onChange={handleFilterChange} />}
      params={{ search, page, perPage }}
      data={data?.items ?? []}
      totalCount={data?.total}
      isLoading={isLoading}
      isFetching={isFetching}
      onParamsChange={setParams}
      isFullscreen
    />
  );
};

export default BeaconsList;
