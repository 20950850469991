import { useCallback, useMemo } from "react";
import { URLSearchParamsInit, useSearchParams } from "react-router";

import paramsToObject from "@/modules/common/utils/paramsToObject";

type Parameters<K extends string | number | boolean | null> = Record<string, K>;

type UseQueryParams<
  K extends string | number | boolean | null,
  T extends Parameters<K>
> = [params: Parameters<string>, setParams: (params: Partial<T>) => void];

export default function useQueryParams<
  K extends string | number | boolean | null,
  T extends Parameters<K>
>(defaultParams: T, replace?: boolean): UseQueryParams<K, T> {
  const [searchParams, setSearchParams] = useSearchParams(
    defaultParams as URLSearchParamsInit
  );

  const params = useMemo(() => {
    if (searchParams.size === 0) {
      return paramsToObject(
        Object.entries(defaultParams).map(([key, value]) => [
          key,
          value ? value.toString() : "",
        ])
      );
    }

    return paramsToObject(searchParams.entries());
  }, [searchParams, defaultParams]);

  const setParams = useCallback(
    (newParams: Partial<T>) => {
      setSearchParams(
        { ...params, ...newParams },
        {
          replace,
        }
      );
    },
    [setSearchParams, params, replace]
  );

  return [params, setParams];
}
