import fetcher, { mountUrl } from "./fetcher";
import stringfyParams, { ParametersObject } from "./utils/stringifyParams";

function setDefaultContentTypeIfEmpty(options: RequestInit = {}) {
  const headers = options.headers as Record<string, string>;
  if (!headers) {
    return {
      ...options.headers,
      "Content-type": "application/json",
    };
  }

  return {
    ...options.headers,
    "Content-type":
      "Content-type" in headers ? headers["Content-type"] : "application/json",
  };
}

export function get<ResponseData, Params extends ParametersObject>(
  path: string,
  params: Partial<Params> = {},
  options: RequestInit = {},
  meta: Record<string, unknown> = {}
): Promise<ResponseData> {
  const searchParams = stringfyParams(params);
  const url = `${mountUrl(path)}${searchParams ? `/?${searchParams}` : ""}`;

  options.headers = setDefaultContentTypeIfEmpty(options);
  return fetcher<ResponseData>(
    url,
    {
      method: "GET",
      ...options,
    },
    meta
  );
}

export function post<Payload, ResponseData>(
  path: string,
  payload: Payload,
  options: RequestInit = {},
  meta: Record<string, unknown> = {}
): Promise<ResponseData> {
  let body: string | FormData;

  if (payload instanceof FormData) {
    body = payload;
  } else {
    body = JSON.stringify(payload);
    options.headers = setDefaultContentTypeIfEmpty(options);
  }
  return fetcher<ResponseData>(
    mountUrl(path),
    {
      method: "POST",
      body,
      ...options,
    },
    meta
  );
}

export function put<Payload, ResponseData>(
  url: string,
  payload: Payload,
  options: RequestInit = {},
  meta: Record<string, unknown> = {}
): Promise<ResponseData> {
  const body = JSON.stringify(payload);

  options.headers = setDefaultContentTypeIfEmpty(options);
  return fetcher<ResponseData>(
    mountUrl(url),
    {
      method: "PUT",
      body,
      ...options,
    },
    meta
  );
}

export function patch<Payload, ResponseData>(
  url: string,
  payload: Payload,
  options: RequestInit = {},
  meta: Record<string, unknown> = {}
): Promise<ResponseData> {
  let body: string | FormData;

  if (payload instanceof FormData) {
    body = payload;
  } else {
    body = JSON.stringify(payload);
    options.headers = setDefaultContentTypeIfEmpty(options);
  }
  return fetcher<ResponseData>(
    mountUrl(url),
    {
      method: "PATCH",
      body,
      ...options,
    },
    meta
  );
}

export function del<T>(
  url: string,
  options: RequestInit = {},
  meta: Record<string, unknown> = {}
): Promise<T> {
  options.headers = setDefaultContentTypeIfEmpty(options);
  return fetcher<T>(
    mountUrl(url),
    {
      method: "DELETE",
      ...options,
    },
    meta
  );
}
